import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useClientsList() {
    const toast = useToast()
    const refListTable = ref(null)
    const tableColumns = [
        { key: 'id', label: 'ID', sortable: false },
        //{ key: 'avatar', label: 'Avatar', sortable: false , tdClass:'width-200 p-1'},
        { key: 'name',label:'Name', sortable: false },
        { key: 'group_name',label:'Group', sortable: false },
        { key: 'is_active',label:'Active', sortable: false },
        { key: 'actions' }       
    ]
    const perPage = ref(10)
    const userData = ref([])
    const groupQuery = ref('');
    const totals = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const items = ref([]);
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totals.value,
        }
    })

    const refetchData = () => {
        fetchClients()
        refListTable.value.refresh()
    }

    store
        .dispatch('app-client/fetchGroup', 'user')
        .then(response => {
            const data = response.data.data;
            const dropdownGroup = response.data.data;
        }
    )

    watch([currentPage, perPage, searchQuery, groupQuery], () => {
        refetchData()
    })

    const fetchClients = (ctx, callback)=>{
        const offset = perPage.value*(currentPage.value-1);

        store
            .dispatch('app-client/fetchUsers', {
                perpage: perPage.value,
                offset: offset,
                keyword: searchQuery.value,
                groupQuery: groupQuery.value,
                groupType: 'user',
                order_by: 'id DESC'
            })
            .then(response => {
                userData.value = response.data.data
                totals.value = response.data.total
            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching User' list",
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: `${error?.response?.data?.message??'Something went wrong'}`
                    },
                })
            })
    }

    const csvExport = ()=>{
        const offset = perPage.value*(currentPage.value-1);

        store
            .dispatch('app-client/fetchClients', {
                perpage: 1000000,
                offset:offset,
                keyword:searchQuery.value,
                groupType: 'user',
                groupQuery:groupQuery.value,
            })
            .then(response => {
                const arrData = response.data.data;
                let csvContent = "data:text/csv;charset=utf-8,";
                csvContent += [
                    Object.keys(arrData[0]).join(";"),
                    ...arrData.map(item => Object.values(item).join(";"))
                ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

                const data = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", data);
                link.setAttribute("download", "export.csv");
                link.click();
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching Owner' list",
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    const deleteData = (id) => {
        store.dispatch('app-client/fetchDelete',id).then(response=>{
            toast({
                component: ToastificationContent,
                props: {
                    title: "Delete Success",
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                },
            })

            refetchData();
        }).catch(()=>{
            toast({
                component: ToastificationContent,
                props: {
                    title: "Error Delete Property",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
    }

    return {
        fetchClients,
        csvExport,
        tableColumns,
        perPage,
        currentPage,
        totals,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,
        groupQuery,
        deleteData,
        refetchData,
        userData,
    }
}